.news-list {
  padding: 20px;
}

.news-item {
  background-color: #f8f9fc;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
}

.news-item h3 a {
  text-decoration: none;
  color: black; 
}

.news-item h3 a:hover {
  color: blue;
}
@media  (max-width: 426px) {
  .news-item h3 a {
    text-decoration: none;
    color: black; 
    font-size: 15px;
  }
  .news-item p {
    font-size: 14px;
  }
}
