.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0B1C3F;
  padding: 10px 20px;
  color: white;
  
}
@media  (max-width: 426px) {
  .navbar-logo{
    height: 20px;
    margin-right: 15px;
  }
  .nav-left .nav-link {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    font-size: 13px;
  }
  .breadcrumb {
    font-size: 12px;
    color: #ccc;
   
  }
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0B1C3F;
    padding: 10px 20px;
    color: white;
    
  }

}

@media  (min-width: 426px) {
  .navbar-logo {
    height: 40px; /* Adjust the height based on your logo */
    margin-right: 15px;
  }
  .nav-left .nav-link {
    color: white;
    text-decoration: none;
    margin: 0 10px;
  }
  .breadcrumb {
    font-size: 14px;
    color: #ccc;
    margin-top: 5px;
  }}


.nav-left {
  display: flex;
  align-items: center;
}



.nav-left .nav-link:hover {
  text-decoration: underline;
}

.nav-right {
  display: flex;
  align-items: center;
}

.nav-right .nav-link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.language-btn {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}



.breadcrumb-link {
  color: white;
  text-decoration: none;
}

.breadcrumb-link:hover {
  text-decoration: underline;
}