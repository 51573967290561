.news-detail {
    padding: 20px;
    max-width: 85%;
    margin: 0 auto;
  }
  
  .news-detail h2 {
    font-size: 24px;
    font-weight: bold;
    color: #2c3e50;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .news-date {
    font-size: 14px;
    color: gray;
    margin-bottom: 15px;
    display: block;
  }
  
  .news-detail p {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 10px;
  }

  .news-detail pre {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
    margin-bottom: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif;
  }
  
  
  .news-detail a {
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
    color: #364bec;
    font-weight: bold;
  }
  
  .news-detail div {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif;
    color: #666;
  }
  
  .news-detail a:hover {
    text-decoration: underline;
  }