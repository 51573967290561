.sidebar {
    width: 250px;
    background-color: #f0f2f5;
    padding: 20px;
    border-right: 1px solid #ddd;
    height: 100vh;
  }
  
  @media  (max-width: 426px) {
    .sidebar {
      width: 250px;
      background-color: #f0f2f5;
      padding: 20px;
      border-right: 1px solid #ddd;
      height: 100vh;
      display: none;
    }
  }
  
  

  .sidebar h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .sidebar nav ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar nav ul li {
    margin-bottom: 10px;
  }
  
  .sidebar-link {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
  }
  
  .sidebar-link:hover,
  .sidebar-link.active {
    background-color: #e0e4e9;
    color: blue;
  }
  
  .icon {
    margin-right: 10px;
  }