.landing-container {
    text-align: center;
    max-width: 100%;
  }
  
  .btn-primary {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
  }

  .land-img{
    width: 100%;
  }